<template>
  <div class="goodseditprice">
    <van-nav-bar
      title="全局改价"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell-group title="批量修改的等级" :border="false">
      <van-cell center>
        <template #title>
          <van-tag :color="changeceo == 1 ? '#FFEDE8' : '#F6F6F6'" :text-color="changeceo==1 ? '#D51231' : '#333333'" @click="changelevel('ceo')">CEO</van-tag>
          <van-tag :color="changeding == 1 ? '#FFEDE8' : '#F6F6F6'" :text-color="changeding==1 ? '#D51231' : '#333333'" @click="changelevel('ding')">顶级</van-tag>
          <van-tag :color="changete == 1 ? '#FFEDE8' : '#F6F6F6'" :text-color="changete==1 ? '#D51231' : '#333333'" @click="changelevel('te')">特级</van-tag>
          <van-tag :color="changepu == 1 ? '#FFEDE8' : '#F6F6F6'" :text-color="changepu==1 ? '#D51231' : '#333333'" @click="changelevel('pu')">普通</van-tag>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="商品售价设置（加价）" :border="false">
      <van-cell center>
        <template #title>
          <van-tag :color="type == 1 ? '#FFEDE8' : '#F6F6F6'" :text-color="type==1 ? '#D51231' : '#333333'" @click="addprice(1)">原销售价</van-tag>
          <van-tag :color="type == 2 ? '#FFEDE8' : '#F6F6F6'" :text-color="type==2 ? '#D51231' : '#333333'" @click="addprice(2)">加价1元</van-tag>
          <van-tag :color="type == 3 ? '#FFEDE8' : '#F6F6F6'" :text-color="type==3 ? '#D51231' : '#333333'" @click="addprice(3)">加价5%</van-tag>
          <van-tag :color="type == 4 ? '#FFEDE8' : '#F6F6F6'" :text-color="type==4 ? '#D51231' : '#333333'" @click="addprice(4)">加价10%</van-tag>
        </template>
      </van-cell>
      <van-cell center value="点击修改自定义价格" is-link title-class="price_show" @click="addprice(5)">
        <template #icon>
          <van-tag :color="type == 5 ? '#FFEDE8' : '#F6F6F6'" :text-color="type==5 ? '#D51231' : '#333333'">自定义价格</van-tag>
        </template>
        <template #title>{{price}}</template>
      </van-cell>
      <van-cell></van-cell>
    </van-cell-group>

    <van-notice-bar
      v-if="changepricetype=='all'"
      wrapable
      :scrollable="false"
      text="由于商品太多，批量改价全部商品每天仅可使用一次。改价预计30分钟，期间不可对商品进行任何操作。"
      style="text-align: left;"
    />

    <div class="btn_main">
      <van-button type="primary" block @click="saveprice">确认修改</van-button>
    </div>

    <!-- 自定义价格 -->
    <van-dialog width="240px" v-model="show" title="自定义价格" show-cancel-button @confirm="setpriceConfirm">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="加价" @click="radio = '1'" center>
            <template #icon>
              <van-radio name="1" checked-color="#EE0A24" />
            </template>
            <template #default>
              <van-field @input="changediyprice" v-model="diyprice" type="digit" />
            </template>
            <template #right-icon>
              元
            </template>
          </van-cell>
          <van-cell title="加价" @click="radio = '2'" center>
            <template #icon>
              <van-radio name="2" checked-color="#EE0A24" />
            </template>
            <template #default>
              <van-field @input="changediyprice" v-model="diyprice2" type="digit" />
            </template>
            <template #right-icon>
              %
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-popup v-model="show1" :close-on-click-overlay="false">
      <van-loading size="24px" @close="closeLoading(1)" @click-overlay="closeLoading(1)" color="#ffffff" text-color="#ffffff" vertical>加载中...</van-loading>
    </van-popup>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { Toast } from 'vant';
export default {
  name: "GoodsPriceEdit",
  data() {
    return {
      xcx: 0,
      price: '0元',
      type: '1',
      allprice: {},
      show: false,
      diyprice: '',
      diyprice2: '',
      radio: '0',
      show1: false,
      total: 0,
      progress: 0,
      gids: [],
      changeceo: '1',
      changeding: '1',
      changete: '1',
      changepu: '1',
      changepricetype: ''
    }
  },
  mounted() {
    this.xcx = this.$route.query.xcx;
    this.init();
    this.gids = localStorage.getItem('upgid') ? JSON.parse(localStorage.getItem('upgid')): [];
    this.changepricetype = localStorage.getItem('changepricetype') ? localStorage.getItem('changepricetype'): '';
    
    console.log(this.gids, "cccccccc");
    if (this.gids.length <= 0 && this.changepricetype =='select') {
      this.$router.back();
    }
    this.total = this.gids.length;
  },
  methods: {
    init() {
      console.log("init")
      this.getallpriceinfo();
    },
    getallpriceinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/goods/get_allprice_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.allprice = res.data.data;
            that.radio = that.allprice.radio;
            that.type = that.allprice.type;
            that.changeceo = that.allprice.changeceo;
            that.changeding = that.allprice.changeding;
            that.changete = that.allprice.changete;
            that.changepu = that.allprice.changepu;
            let str = '';
            if (that.type == 1 || that.type == 2 || (that.type == 5 && that.radio == 1)) {
              str = '元';
            }else {
              str = "%";
            }
            that.price = that.allprice.price+str;
            // that.total = that.allprice.total;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    changelevel(level) {
      console.log(level);
      if(level == 'ceo'){
        if(this.changeceo == '1'){
          this.changeceo = '0';
        }else{
          this.changeceo = '1';
        }
      }else if(level == 'ding'){
        if(this.changeding == '1'){
          this.changeding = '0';
        }else{
          this.changeding = '1';
        }
      }else if(level == 'te'){
        if(this.changete == '1'){
          this.changete = '0';
        }else{
          this.changete = '1';
        }
      }else if(level == 'pu'){
        if(this.changepu == '1'){
          this.changepu = '0';
        }else{
          this.changepu = '1';
        }
      }
    },
    addprice(num) {
      this.type = num;
      if ( num == 5) {
        if (this.radio == '1') {
          this.diayprice = parseFloat(this.price);
        }else if (this.radio == '1') {
          this.diayprice2 = parseFloat(this.price);
        }
        this.show = true;
      }else if (num == 1) {
        this.price = '0元';
      }else if (num == 2) {
        this.price = '1元';
      }else if (num == 3) {
        this.price = '5%';
      }else if (num == 4) {
        this.price = '10%';
      }
    },
    changediyprice(value) {
      console.log(value);
      if (this.radio == '1') {
        this.diyprice2 = '';
      }else if (this.radio == '2') {
        this.diyprice = '';
      }
    },
    setpriceConfirm() {
      console.log(this.diyprice, this.diyprice2);
      if (this.radio == '1') {
        this.price = this.diyprice+'元';
      }else if (this.radio == '2') {
        this.price = this.diyprice2+'%';
      }
    },

    // 保存设置
    saveprice() {
      Dialog.confirm({
        title: '确认修改价格？',
        message: '建议使用百分比。点击确认按钮后所有商品所有规格都会按照此设置加价，以前设置的会失效，是否确认修改？（注：1、若设置价格高于最低价的2倍，则不更新该商品的价格！）',
      })
      .then(() => {
        // on confirm
        this.update();
      })
      .catch(() => {
        // on cancel
      });
    },

    update() {
      let that = null;
      that = this;
      this.show1 = true;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/set_allprice",
          that.$qs.stringify({
            price: parseFloat(that.price),
            type: that.type,
            radio: that.radio,
            changeceo: that.changeceo,
            changeding: that.changeding,
            changete: that.changete,
            changepu: that.changepu,
            gids: that.gids,
          })
        )
        .then((res) => {
          this.show1 = false;
          console.log(res);
          if (res.data.code == 100000) {
            console.log("dddd");
            // that.$toast({
            //   message: res.data.msg ? res.data.msg : '修改成功',
            //   forbidClick: true,
            // })
            if(that.changepricetype == 'select'){
              that.dosetprice();
            }else if(that.changepricetype == 'all'){
              that.doallsetprice();
            }
          }else {
            that.$toast({
              message: res.data.msg ? res.data.msg : '修改失败',
              forbidClick: true,
            })
          }
        })
        .catch((err) => {
          this.show1 = false;
          that.$toast({
              message: err.data.msg ? err.data.msg : '修改失败',
              forbidClick: true,
            })
          console.log(err);
        })
    },

    // 执行修改
    dosetprice() {
      let that = null;
      that = this;
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '已更新'+that.progress+'/'+that.total,
      });

      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/sigleSetPrice",
          that.$qs.stringify({
            price: parseFloat(that.price),
            type: that.type,
            radio: that.radio,
            changeceo: that.changeceo,
            changeding: that.changeding,
            changete: that.changete,
            changepu: that.changepu,
            offset: that.progress,
            gid: that.gids[that.progress]
          })
        )
        .then(res => {
          if (res.data.code == 100000) {
            that.progress++;
            toast.message = `已修改${that.progress}/${that.total}`;
            if (that.progress < that.total) {
              that.dosetprice();
            }else {
              Toast.clear();
              Toast(res.data.msg ? res.data.msg : '修改完成');
            }
          }else {
            Toast.clear();
            Toast('修改到第'+that.progress+'失败');
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    doallsetprice() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/allSetPrice",
          that.$qs.stringify({
            price: parseFloat(that.price),
            type: that.type,
            radio: that.radio,
            changeceo: that.changeceo,
            changeding: that.changeding,
            changete: that.changete,
            changepu: that.changepu
          })
        )
        .then(res => {
          Toast.clear();
          Toast(res.data.msg ? res.data.msg : '已提交批量改价任务');
        })
        .catch(err => {
          console.log(err);
        })
    },
  }
}
</script>
<style lang="less">
.goodseditprice {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #fff;
  .van-cell-group__title {
    text-align: left;
    font-size: 14px;
    color: #333;
  }
  .van-cell {
    .van-cell__title {
      flex: 1;
      text-align: left;
    }
    .price_show {
      padding: 0 10px;
    }
    .van-tag {
      padding: 5px 10px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .btn_main {
    margin: 16px;
    overflow: hidden;
    border-radius: 4px;
  }
  .van-dialog {
    .van-cell__title {
      margin-left:  10px;
    }
    .van-field__body {
      background-color: #ccc;
      max-width: 80px;
      padding: 0 10px;
    }
  }
  .van-popup {
    background-color: rgba(0, 0, 0, 0);
    .van-loading__text {
      color: #fff;
    }
  }
}
</style>